<template>
  <div class="shipApply">
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <!-- 申请时间 -->
        <el-table-column :label="$t('applyTime')">
          <template slot-scope="scope">{{
            scope.row.created_time | secondFormat("LLL")
          }}</template>
        </el-table-column>
        <!-- 协会名称 -->
        <!-- <el-table-column prop="title" :label="$t('AssociationName')" ></el-table-column> -->
        <!-- 申请状态 -->
        <el-table-column :label="$t('applyStatus')" v-if="PJSource != 13 && PJSource != 30 ">
          <div slot-scope="scope">
            <div v-if="scope.row.my_status=== 0" class="status_box">
              <!-- 审核中 -->
              <span style="background: rgb(64, 158, 255)"></span
              ><i>{{ $t("RegisterWait") }}</i>
            </div>
            <div
              v-if="scope.row.my_status == 2"
              class="status_box"
              @click="showResouce(scope.row.reason)"
              style="cursor: pointer"
            >
              <!-- 审核未通过 -->
              <span style="background: #e6a23c"></span
              ><i>{{ $t("applyReject") }}</i>
            </div>
            <div v-else-if="scope.row.my_status==3" class="status_box">
              <!-- 未入账(未缴费)  -->
              <span style="background: rgb(82, 196, 26)"></span
              ><i>{{ $t("Unpaid") }}</i>
            </div>
            <div v-else-if="scope.row.my_status==4" class="status_box">
              <!-- 已入账(已缴费) -->
              <span style="background: rgb(82, 196, 26)"></span
              ><i>{{ $t("Paid") }}</i>
            </div>
            <div v-else-if="scope.row.my_status==5" class="status_box">
              <!-- 已是会员 -->
              <span style="background: rgb(82, 196, 26)"></span
              ><i>{{ $t("AlreadyMember") }}</i>
            </div>
            <div v-else-if="scope.row.my_status==6" class="status_box">
              <span style="background: #b2bec3"></span
              ><i>{{ $t("MemberExpired") }}</i>
            </div>
          </div>
        </el-table-column>
        <el-table-column :label="$t('applyStatus')" v-if="PJSource == 13 || PJSource == 30">
          <div slot-scope="scope">
            <div class="status_box">
              <span :style="scope.row.initiation_status.background"></span
              ><i v-if="scope.row.initiation_status.title">{{ $t(scope.row.initiation_status.title) }}</i><i>{{ $t(scope.row.initiation_status.txt) }}</i>
            </div>
          </div>
        </el-table-column>
        <!-- 编辑 -->
        <el-table-column :label="$t('handle')" v-if="PJSource != 13 && PJSource != 30">
          <template slot-scope="scope">
            <!-- 取消申请 废弃 -->
            <!-- <el-button  type="text"   size="small" @click="cancel(scope.row,scope.$index)">{{$t('Cancelapplication')}}</el-button> -->
            <!-- 详情 -->
            <el-popover
              v-if="scope.row.details_info && scope.row.details_info.length > 0"
              placement="left"
              width="400"
              trigger="click"
            >
              <el-timeline>
                <el-timeline-item
                  v-for="(activity, index) in scope.row.details_info"
                  :key="index"
                  type="primary"
                  :timestamp="activity.created_time | secondFormat"
                >
                  {{ memStatus(activity.operation_type) }}
                </el-timeline-item>
              </el-timeline>
              <el-button slot="reference" type="text" size="small">{{
                $t("Detail")
              }}</el-button>
            </el-popover>
            <el-button v-else type="text" size="small">-</el-button>
          </template>
        </el-table-column>
        <div slot="empty" style="line-height: 548px">
          <p style="color: #909399">{{$t('nodata')}}</p>
        </div>
      </el-table>
    </div>
  </div>
</template>

<script>
// 入会申请
export default {
  layout: "memberCenter",
  name: "membershipApply",
  data() {
    return {
      total: 0,
      currentPage: 1,
      limit: 10,
      loading: false,
      tableData: [],
      tableHeight: 100,
    };
  },
  mounted() {
    this.getList();
  },
  filters: {
    
  },
  methods: {
    memStatus(value) {
      //1,  # 操作类型：1-审核通过，2-审核拒绝，3-签署合同，4-开发票，5-入账，6-设置会员
      switch (value * 1) {
        case 1:
          return this.$t('RegisterWaitpass');
          break;
        case 2:
          return this.$t('RegisterRefuse');
          break;
        case 3:
          return this.$t('qianshuhetong');
          break;
        case 4:
          return this.$t('kaifapiao');
          break;
        case 5:
          return this.$t('ruzhang');
          break;
        case 6:
          return this.$t('vipBecome');
          break;
        default:
          break;
      }
    },
    showResouce(resouce) {
      this.$confirm(resouce, this.$t('reason'), {
        confirmButtonText: this.$t('close'),
        showCancelButton: false,
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    pageChange(index) {
      this.currentPage = index;
      this.getList();
    },
    async getList(scope, status) {
      this.loading = true;
      let params = {
        limit: this.limit,
        start: this.currentPage - 1,
        source: this.PJSource,
      };
      let api_name = "baseConsole/getSiffaApplyList";
      if (this.PJSource == 13 ||this.PJSource == 30 ) {
        params.company_id = this.USER_INFO.company_id;
        params.company_name = this.USER_INFO.company_name_zh || this.USER_INFO.company_name_en;
        params.query_type = "1";
        params.nopage = "1";
        api_name = "baseConsole/getScbaApplyFormQuery";
      }else {
        params.apply_type = 1;//活动申请
        params.user_id=this.USER_INFO.id
      }
      let result = await this.$store.dispatch(api_name, params);
      if (result.success && this.PJSource == 13 || this.PJSource == 30) {
        let res = result.data;
        for await (let item of res) {
          item.initiation_status = await this.scbaJudgeStatus(item);
          item.created_time = item.apply_time;
        }
        this.tableData = res;
        this.loading = false;
      } else if (result.success) {
        let res = result.data;
        res.forEach((item) => {
          item.my_status = this.Judging_status(item);
        });
        this.tableData = res;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    //判断状态
    Judging_status(row) {
      let my_status = 0;
      if (row.audit_status == 0) {
        my_status = 0; //待审核
      }
      if (row.audit_status == 2) {
        my_status = 2; //审核拒绝
      }
      if (row.audit_status == 1) {
        //1-审核通过--》判断是否缴费
        if (row.booked_status == 0) {
          my_status = 3; //0-未入账(未缴费)
        }
        if (row.booked_status == 1) {
          //已缴费--->根据status判断是否是会员
          if (row.status == 0) {
            my_status = 4; //已入账(已缴费)
          }
          if (row.status == 1) {
            my_status = 5; //已是会员
          }
          if (row.status == 2) {
            my_status = 6; //已过期
          }
        }
      }
      console.log(my_status);
      return my_status;
    },
    // scba状态判断
    async scbaJudgeStatus(row) {
      let initiation_status = {};
      if (row.form_status == 0) {
        initiation_status = {
          background: 'background: rgb(64, 158, 255)',
          title: 'form',
          txt: 'RegisterWait'
        };
        return initiation_status;
      }else if (row.form_status == 1) {
        if (row.data_status == 1) {
          initiation_status = {
            background: 'background: rgb(64, 158, 255)',
            title: 'information',
            txt: 'RegisterWait'
          };
        }else if (row.data_status == 2) {
          if (this.USER_INFO.aila_no) {
            initiation_status = {
              background: 'background: rgb(82, 196, 26)',
              title: '',
              txt: 'AlreadyMember'
            };
          } else {
            initiation_status = {
              background: 'background: rgb(82, 196, 26)',
              title: 'information',
              txt: 'RegisterWaitpass'
            };
          }
        }else if (row.data_status == 3) {
          initiation_status = {
            background: 'background: #e6a23c',
            title: 'information',
            txt: 'applyReject'
          };
        }else {
          initiation_status = {
            background: 'background: #b2bec3',
            title: 'information',
            txt: 'noUploading'
          };
        }
        return initiation_status;
      }else if (row.form_status == 2) {
        initiation_status = {
          background: 'background: #e6a23c',
          title: 'form',
          txt: 'applyReject'
        };
        return initiation_status;
      }else if (row.form_status == 4) {
        initiation_status = {
          background: 'background: #e6a23c',
          title: 'form',
          txt: 'applyReject'
        };
        return initiation_status;
      }else if (row.form_status == 3) {
        if (!this.USER_INFO.aila_no) {
          try {
            let user_info = await this.$store.dispatch("baseStore/baseSign_getUserInfo", { user_id: this.USER_INFO.id });
            return this.getUserStatus();
          } catch (err) {
            return this.getUserStatus();
          }
        }else {
          return this.getUserStatus();
        }
      }
    },
    // 获取状态
    getUserStatus(){
      let initiation_status = {};
      if (this.USER_INFO.aila_no) {
        initiation_status = {
          background: 'background: rgb(82, 196, 26)',
          title: '',
          txt: 'AlreadyMember'
        };
      } else {
        initiation_status = {
          background: 'background: rgb(82, 196, 26)',
          title: 'information',
          txt: 'RegisterWaitpass'
        };
      }
      return initiation_status;
    },
    //取消入会申请--该功能已关闭
    async cancel(row, index) {
      this.$confirm(this.$t("isCencelApply"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let params = {
            user_id: this.USER_ID,
            apply_type: 1, //入会申请
            source: "7",
            apply_id: row.apply_id,
          };
          let result = await this.$store.dispatch(
            "API_conference/getSiffaApplyList",
            params
          );
          if (result.success) {
            this.loading = false;
            this.getList();
          } else {
            this.loading = false;
          }
        })
        .catch((e) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.shipApply {
  padding: 12px 0;
}
.status_box {
  span {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.pagination {
  text-align: right;
  margin-top: 15px;
}
</style>